body {
  margin: 0;
}

nav {
  padding: 2rem;
  box-shadow: 0 5px 15px #e0e0e0;
  text-align: center;
}

nav a {
  color: #303030;
  text-decoration: none;
  padding: 0 5rem;
}

nav a:hover {
  text-decoration: underline;
}

.cta-container {
  padding: 2rem 0;
  text-align: center;
}

.cta-button {
  background: green;
  padding: 1rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  border: 2px solid green;
}

.cta-button:hover {
  box-shadow: 1px 1px 4px green;
}

.selectors {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 60%;
  margin-left: 20%;
}

.selectors p {
  margin-top: 5rem;
  font-size: 24px;
  font-weight: bold;
}

.home {
  max-width: 40vw;
  margin-left: 30vw;
}

.home a {
  cursor: pointer;
}

.comparison {
  text-align: center;
}

.comparison span,
.statistics span {
  cursor: pointer;
}

.comparison span:hover,
.statistics span:hover {
  text-decoration: underline;
}

.comparison .instructions,
.statistics .instructions {
  max-width: 40%;
  text-align: left;
  margin-left: 30%;
}

.comparison .instructions p,
.statistics .instructions p {
  margin: 2rem 0;
}

.selector-container {
  text-align: center;
  width: 300px;
  margin: 24px;
}

.selector-container .selector-button {
  padding: 8px;
  background-color: grey;
  border: none;
  border-radius: 4px;
  color: white;
  margin: 24px;
  cursor: pointer;
}

.selector-container .select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-inverted-text: var(--select-search-background);
  --select-search-highlight: #eff1f5;
  --select-search-font: 'Inter', sans-serif;
}

.selector-container input {
  cursor: pointer;
}

.selector-button:hover {
  background-color: darkgrey;
}

.chart-container {
  text-align: center;
}

.slider-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  justify-content: center;
}

.slider-container span {
  margin-top: 6px;
}

.horizontal-slider {
  width: 50%;
}

.horizontal-slider .track {
  background-color: #f0f0f0;
  height: 4px;
  border-radius: 2px;
  margin-top: 11px;
}

.horizontal-slider .thumb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 32px;
  width: 32px;
  color: black;
  background-color: white;
  border: 1px solid black;
  border-radius: 16px;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  align-content: center;
  z-index: 0 !important;
}

.table-button {
  cursor: pointer;
  border-radius: 4px;
  color: white;
  background-color: #145da0;
  padding: 0.75rem 1rem;
  border: none;
  font-weight: bold;
}

.statistics {
  text-align: center;
}

.table-container table {
  padding: 2rem;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  min-width: 400px;
}

.table-container td,
.table-container th {
  padding: 0.5rem 1rem 0.3rem 0.5rem;
}

.table-container tr:nth-child(2n) {
  background-color: #ddd;
}

.align-right {
  text-align: right;
}

@media (max-width: 700px) {
  nav a {
    margin: 0 1rem;
    padding: 0;
  }

  .home {
    max-width: 100vw;
    margin-left: 0;
    padding: 0 2rem;
  }

  .table-container td,
  .table-container th {
    padding: 0.5rem 0.1rem 0.3rem 0.1rem;
  }

  .table-container table {
    padding: 0.1rem;
    text-align: left;
    min-width: 300px;
  }

  .comparison .instructions,
  .statistics .instructions {
    max-width: 90%;
    margin-left: 5%;
  }

  .selectors {
    flex-direction: column;
    width: 100%;
    padding: 1rem 0;
    margin: 0;
    text-align: center;
  }

  .selectors p {
    margin-top: 0;
    font-size: 16px;
  }

  .selector-container {
    width: 100%;
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
